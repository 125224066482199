import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Grid from 'components/Grid.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import _ from 'lodash';

const PrecautionsInCareNotifications = ({ isEhrField, filteredPrecautions }) => {
	const suspiciousConditionCode = '26544005';

	return (
		<>
			{filteredPrecautions.length > 0 && (
				<Grid
					rows='repeat(4, min-content)'
					gridGap='var(--spacing-s)'
					className={classNames(
						'precautions-grid',
						filteredPrecautions.length > 4 && filteredPrecautions.length <= 8 ? 'precautions-8' : '',
						filteredPrecautions.length > 8 ? 'precautions-10' : ''
					)}>
					{filteredPrecautions.map(item => (
						<div
							className='precaution-box'
							style={{
								background: item.boxColor,
							}}>
							{(!isEhrField || item.id === suspiciousConditionCode) && (
								<span style={{ color: item.textColor }}>{item.abbreviation}</span>
							)}
							{isEhrField && item.id !== suspiciousConditionCode && (
								<img src={`${healthCareCdnUrl}monitoring/precautions/${item.icon}`} alt='icon' />
							)}
						</div>
					))}
				</Grid>
			)}
		</>
	);
};

export default PrecautionsInCareNotifications;
