import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getRoomSettings = async (companyId, roomId, settingsCategory) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/rooms/${roomId}/settings?settingsCategory=${settingsCategory}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMonitoringSettingOptions = async (teamId, settingTypeId) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/teams/${teamId}/settings/${settingTypeId}/options`);
		return data;
	} catch (error) {
		return { error };
	}
};
