import { gatewayApi } from 'constants/global-variables.js';
import { SectorTypes } from 'constants/enums.js';

export const getHealthSystemSubTree = async (healthSystemId, companyId) => {
	try {
		const response = await gatewayApi.get(`/v1/organizations/${companyId}/health-systems/${healthSystemId}/full`);
		const { regions } = response.data;
		const treeData = [];
		regions.forEach((region, regionIndex) => {
			treeData.push({
				name: region.name,
				icon: 'local_hospital',
				type: SectorTypes.REGION,
				subOptions: [],
				id: region.id,
			});

			region.hospitals.forEach((hospital, hospitalIndex) => {
				treeData[regionIndex].subOptions.push({
					name: hospital.name,
					icon: 'business',
					type: SectorTypes.HOSPITAL,
					subOptions: [],
					id: hospital.id,
					regionId: region.id,
				});

				hospital.departments.forEach((department, departmentIndex) => {
					treeData[regionIndex].subOptions[hospitalIndex].subOptions.push({
						name: department.name,
						icon: 'account_balance',
						type: SectorTypes.DEPARTMENT,
						subOptions: [],
						id: department.id,
						hospitalId: hospital.id,
						regionId: region.id,
					});

					department.floors.forEach((floor, floorIndex) => {
						treeData[regionIndex].subOptions[hospitalIndex].subOptions[departmentIndex].subOptions.push({
							name: floor.name,
							icon: 'money',
							type: SectorTypes.FLOOR,
							subOptions: [],
							id: floor.id,
							departmentId: department.id,
							hospitalId: hospital.id,
							regionId: region.id,
						});

						floor.rooms.forEach((room, roomIndex) => {
							treeData[regionIndex].subOptions[hospitalIndex].subOptions[departmentIndex].subOptions[floorIndex].subOptions.push({
								name: room.name,
								icon: 'meeting_room',
								type: SectorTypes.ROOM,
								subOptions: [],
								floorId: floor.id,
								departmentId: department.id,
								hospitalId: hospital.id,
								regionId: region.id,
								id: room.id,
								roomIndex: roomIndex,
								status: 'online',
								helloDeviceId: room.helloDeviceId ? Number(room.helloDeviceId) : null,
							});
						});
					});
				});
			});
		});
		return treeData;
	} catch (error) {
		return { error };
	}
};
