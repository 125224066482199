import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getDeviceOwnerPatientWithRetry = async (companyId, deviceId, retryCount = 0) => {
	const maxRetries = 3;
	try {
		const url = `v1/healthcare/organizations/${companyId}/devices/${deviceId}/patient`;
		const response = await gatewayApi.get(url);
		if (response.status === 403) {
			if (retryCount < maxRetries) {
				await new Promise(resolve => setTimeout(resolve, 1000));
				return getDeviceOwnerPatientWithRetry(companyId, deviceId, retryCount + 1);
			} else {
				throw new Error('Max retry attempts reached.');
			}
		} else {
			const { data } = await gatewayApi.get(url);
			return data;
		}
	} catch (error) {
		throw error;
	}
};
export const updatePatientAiSettings = async ({ roomId, deviceId, patientId, sendData }) => {
	try {
		const { data } = await gatewayApi.patch(
			`/v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings`,
			sendData
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientByUserId = async (companyId, userId) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${companyId}/patient/${userId}/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};
