import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import Grid from 'components/Grid.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { AlertTypes, UserRoles } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { LOCALES } from 'i18n-translations/locales.js';
import PopUpAlert from 'components/PopUpAlert.jsx';

const MainLayout = props => {
	const [isNewPatientCheckInModalVisible, setIsNewPatientCheckInModalVisibility] = useState(false);
	const [selectedPage, setSelectedPage] = useState('');
	const [taskCompleted, setTaskCompleted] = useState(null);
	const dispatch = useDispatch();
	const healthSystems = useSelector(state => state.healthSystems);
	const locale = useSelector(state => state.language.locale);
	const history = useHistory();
	const isDarkMode = true;

	const checkInNewPatient = () => {
		getStorage().removeItem('patientId');
		if (!selectedPage) {
			history.push('/check-in-patient');
		} else {
			history.push(selectedPage);
		}
		setIsNewPatientCheckInModalVisibility(false);
	};

	const closeCheckInModal = () => {
		setSelectedPage('');
		setIsNewPatientCheckInModalVisibility(false);
	};

	const getAlertMessageContent = data => ({
		title: data.measurementAlertType.name,
		contentText: translate('patientHasMeasured', {
			value1: data.patientFullName,
			value2: data.measurementAlertType.name,
		}),
	});

	const handleMeasurementAlertClick = () => {
		if (healthSystems.measurementAlertData.patientUserId) {
			const url = `/patients/${healthSystems.measurementAlertData.patientUserId}`;
			dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null));
			history.push(url);
		}
	};

	return (
		<>
			{
				<Grid
					columns={'1fr'}
					stretch='100dvh'
					className={classNames(
						'main-layout new-experience',
						locale === LOCALES.ARABIC ? 'direction-rtl-wrapper' : '',
						[UserRoles.VISITOR, UserRoles.FAMILY_MEMBER].includes(getUserRole()) ? 'visitor-view' : '',
						healthSystems.isFullscreen ? 'full-screen-wrapper' : '',
						isDarkMode ? 'dark-mode' : ''
					)}>
					<div className='app-wrapper'>
						<Grid
							className={props.isSecondColumnCollapsed ? 'collapsed-second-column' : ''}
							columns={props.twoColumns ? '1fr 3fr' : '1fr'}
							stretch='100%'>
							<div className='full-width'>{props.children}</div>
						</Grid>
						{props.showWarningAlert && healthSystems.measurementAlertData && (
							<PopUpAlert
								display={healthSystems.measurementAlertData}
								isRightBottom={true}
								title={getAlertMessageContent(healthSystems.measurementAlertData).title}
								alertType={AlertTypes.DANGER}
								contentText={getAlertMessageContent(healthSystems.measurementAlertData).contentText}
								isSilent={true}
								onTextClick={handleMeasurementAlertClick}
								onAlertClose={() => dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null))}
								isMeasurementAlert={true}
								selfCloseTimeOut={5000}
							/>
						)}
					</div>
				</Grid>
			}
			{isNewPatientCheckInModalVisible && (
				<Modal
					display={isNewPatientCheckInModalVisible}
					onModalClose={closeCheckInModal}
					onModalSubmit={checkInNewPatient}
					primaryButtonLabel={translate('discardAndContinue')}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					shouldSubmitOnEnter={false}
					position='center'>
					<Form height={220} className='discard-changes-form'>
						<h3>{translate('discardChanges')}</h3>
						<p>{translate('checkInOtherPatient')}</p>
					</Form>
				</Modal>
			)}
			<div className='build-number'>
				<Alert
					display={taskCompleted}
					fixed={true}
					variant='dark'
					message={translate('taskCompleted')}
					onClose={() => setTaskCompleted(null)}
				/>
			</div>
		</>
	);
};

export default MainLayout;
