import translate from 'i18n-translations/translate.jsx';

export const SocketConnectionStates = {
	AUTHORIZED: 'Authorized',
	CONNECTED: 'Connected',
	CONNECTING: 'Connecting',
	DISCONNECTED: 'Disconnected',
};

export const SectorTypes = {
	HEALTH_SYSTEM: 'healthSystem',
	REGION: 'region',
	HOSPITAL: 'hospital',
	DEPARTMENT: 'department',
	FLOOR: 'floor',
	ROOM: 'room',
	COMPANY: 'company',
};

export const ObservationType = {
	TOBACCO_SMOKE: 1,
	PAIN_MANAGEMENT: 2,
	LAB_RESULT: 3,
	PATIENT_LOGS: 4,
	LABOR: 5,
	BRADEN_SCORE: 7,
	PRIMARY_PATIENT_RISK_FACTOR: 15,
	PRIMARY_ADVERSE_EVENT: 16,
	ADDITIONAL_PATIENT_NOTES: 17,
	ADDITIONAL_TITLE_BAR_NOTES: 18,
	BEHAVIORAL_SAFETY_PLAN: 12,
};

export const MeasurementActivityTypes = {
	MEASUREMENT_ALERT_SENT: 1,
	MEASUREMENT_ALERT_RECEIVED: 2,
	ADDED_AS_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	MEASUREMENT_FAKE_ALERT: 6,
	MEASUREMENT_FORWARD_TO_NURSES: 7,
};

export const CallTypes = {
	AUDIO: 1,
	VIDEO: 2,
	SCREENSHARE: 3,
	SECURITY_CAM: 4,
	LIVEBROADCAST: 5,
	MONITORING: 6,
	FIRST_RESPONDER: 7,
	AMBIENT_AND_VIDEO: 97,
	AMBIENT: 98,
	QUEUEING: 99,
};

export const DeviceStatusMessages = {
	deviceOnCall: translate('deviceOnCall'),
	deviceOnCallWithNurse: translate('deviceOnCallWithNurse'),
	deviceOffline: translate('deviceOffline'),
	initiatingMonitoring: translate('initiatingMonitoring'),
	notAnswering: translate('notAnswering'),
	leftCall: translate('leftCall'),
	privacyModeOn: translate('privacyModeOn'),
	disconnected: translate('disconnected'),
	reconnecting: translate('reconnecting'),
	removed: translate('sessionEndedByAdmin'),
	disconnectedByCall: translate('disconnectedByCall'),
	inviteDenied: translate('conferenceEndedParticipantInviteDenied'),
	maximumNumberOfMonitoringSessions: translate('maximumNumberOfMonitoringSessions'),
	failedToGetInfo: translate('failedToGetParticipantInfo'),
};

export const ParticipantRemoveReason = {
	UNDEFINED: 0,
	PARTICIPANT_LEAVE: 1,
	DISCONNECTED: 2,
	PARTICIPANT_FORCED_LEAVE: 3,
	CLEAR_PARTICIPANT_OLD_CONFERENCES: 4,
	PARTICIPANT_CLEANUP: 5,
	PASSWORD_CHANGED: 6,
	IDLE: 7,
	CONFERENCE_TERMINATED_BY_ADMINISTRATOR: 8,
	DISCONNECTED_PARTICIPANT_CLEANUP: 9,
	SOMEONE_ELSE_ANSWERED: 10,
	DISCONNECTED_BY_CALL: 11,
	FAILED_TO_GET_INFO: 12,
};

export const ParticipantState = {
	CONNECTING: {
		type: 0,
		message: 'Connecting',
	},
	CONNECTED: {
		type: 1,
		message: 'Connected',
	},
	BUSY: {
		type: 2,
		message: 'Busy',
	},
	DECLINED: {
		type: 3,
		message: 'Declined',
	},
	OFFLINE: {
		type: 4,
		message: 'Offline',
	},
	LEFT_CALL: {
		type: 5,
		message: 'Left the call',
	},
	NOT_ANSWERING: {
		type: 6,
		message: 'Not answering',
	},
	CANT_CONNECT: {
		type: 7,
		message: 'Cant connect',
	},
	DISRUPTED: {
		type: 8,
		message: 'Disrupted',
	},
	RECONNECTING: {
		type: 9,
		message: 'Reconnecting',
	},
	DISCONNECTED: {
		type: 10,
		message: 'Disconnected',
	},
	REMOVED: {
		type: 11,
		message: 'Removed',
	},
	FAILED_TO_GET_INFO: {
		type: 98,
		message: 'Failed to get info',
	},
	INVITE_DENIED: {
		type: 99,
		message: 'Invite denied',
	},
};

export const PatientAiSetting = {
	RAILS: 1,
	PATIENT_SITTING: 2,
	PATIENT_STANDING: 3,
	PATIENT_FACE_DOWN: 4,
	PATIENT_LYING_SIDE: 5,
	PATIENT_LYING_FRONT: 6,
	PATIENT_LYING_RIGHT_SIDE: 7,
	FALL_DETECTED: 8,
	PRESSURE_INJURY: 9,
	PATIENT_GETTING_OUT_OF_BED: 10,
	SKELETON_PRIVATE_MODE: 11,
	PATIENT_MOBILITY: 12,
	PATIENT_LYING_LEFT_SIDE: 13,
	VITAL_SIGNS_AI: 14,
	HAND_WASHING: 15,
	IV_BAG: 16,
	AI_DRAWING: 17,
	SOUND_ON_AI_ALERT: 18,
	FALL_PREVENTION: 999,
	AI_VOICE_ANALYSIS: 19,
};

export const VoiceOverAlertTypes = {
	GENERIC: 'generic',
};

export const AudioRoutingDeviceTypes = {
	HELLO_1: 1,
	HELLO_2: 2,
	HELLO_2_PLUS: 3,
	HELLO_3: 4,
	PHONE: 5,
	TABLET: 6,
};

export const AudioRoutingOption = {
	ALWAYS_FROM_HELLO: 'always-from-hello',
	TV_PATIENT_NURSE: 'tv-patient-sees-nurse',
	TV_SOURCE_AVAILABLE: 'tv-source-available',
	ALWAYS_PILLOW_SPEAKER: 'always-from-pillow-speaker',
	ALWAYS_FROM_TABLET: 'always-from-tablet',
	ALWAYS_FROM_PHONE: 'always-from-phone',
	EXTERNAL_SPEAKER_DETECTED: 'external-speaker-detected',
};

export const StatAlarmType = {
	ALARM_1: 'sound-1',
	ALARM_2: 'sound-2',
};

export const TalkToPatient = {
	AUDIO_ONLY: 'audio-only',
	AUDIO_VIDEO: 'audio-video',
};

export const UserLanguage = {
	ENGLISH: 'English',
	ALBANIAN: 'Albanian',
	ARABIC: 'Arabic',
	SPANISH: 'Spanish',
	GERMAN: 'German',
};

export const UserRoles = {
	DOCTOR: 'Doctor',
	NURSE: 'Nurse',
	PATIENT: 'Patient',
	ADMIN: 'Admin',
	SUPER_USER: 'SuperUser',
	SUPER_ADMIN: 'SuperAdmin',
	OWNER: 'Owner',
	DIGITAL_CLINICIAN: 'DigitalClinician',
	GUEST: 'Guest',
	VIRTUAL_SITTER: 'VirtualSitter',
	VISITOR: 'Visitor',
	ROOM_SIGN: 'RoomSign',
};

export const ScreenTypes = {
	LAPTOP: { type: 1, isSmall: false },
	TABLET: { type: 2, isSmall: true },
	MOBILE: { type: 3, isSmall: true },
};

export const WindowSize = {
	LAPTOP: 1024,
	TABLET: 768,
	MOBILE: 425,
};

export const TreeHierarchyType = {
	DEFAULT_TREE: 0,
	HOSPITAL_DEPT_FLOOR_ROOM: 1,
	HOSPITAL_DEPT_ROOM: 2,
	HOSPITAL_FLOOR_ROOM: 3,
	HOSPITAL_ROOM: 4,
};

export const AppointmentStatus = {
	ACCEPTED: 1,
	PENDING: 2,
	REJECTED: 3,
	RESCHEDULED: 4,
	CANCELED: 5,
	COMPLETED: 6,
};

export const AttachmentType = {
	DOCUMENT: 0,
	PICTURE: 1,
	VIDEO: 2,
};

export const CallRequestType = {
	RAPID_REQUEST_TYPE: 'rapidRequestType',
	ADMISSION_REQUEST_TYPE: 'admissionRequestType',
	REGULAR_REQUEST_TYPE: 'regularRequestType',
};

export const CompanyRoles = {
	OWNER: 1,
	ADMIN: 2,
	MEMBER: 3,
	GUEST: 4,
	SUPPORT: 5,
	VIRTUAL_CARE_PROVIDER: 6,
	VIRTUAL_SITTER: 7,
	SUPER_USER: 8,
	SUPER_ADMIN: 9,
	DIGITAL_CLINICIAN: 10,
	DOCTOR: 11,
	VISITOR: 12,
	PATIENT: 13,
	VIRTUAL_PATIENT: 14,
};

export const DeviceFamilyTypes = {
	TABLET: 'Tablet',
	HELLO_2: 'HELLO 2',
	HELLO_2_PLUS: 'HELLO 2+',
	HELLO_3: 'HELLO 3',
	EXPAND_VISION_3: 'EXPAND VISION 3',
	EXPAND_VISION_5: 'EXPAND VISION 5',
	EXPAND_VISION_3T: 'EXPAND VISION 3T',
	EXPAND_VISION_5T: 'EXPAND VISION 5T',
};

export const DeviceListLevel = {
	HEALTH_SYSTEM: 0,
	REGION: 1,
	HOSPITAL: 2,
	DEPARTMENT: 3,
	FLOOR: 4,
	ROOM: 5,
	ORGANIZATION: 6,
};

export const Gender = {
	UNAVAILABLE: 0,
	MALE: 1,
	FEMALE: 2,
	NON_BINARY: 3,
};

export const IframeIntregrationsIds = {
	AMWELL: 1,
	PHILIPS: 2,
};

export const InviteParticipantFailureReason = {
	INVITE_DENIED: 'inviteParticipant.denied',
	FAILED_TO_GET_INFO: 'inviteParticipant.failedToGetInfo',
};

export const KeyCodes = {
	PERIOD: 190,
	NUMPAD_PERIOD: 110,
	MINUS: 189,
	NUMPAD_SUBTRACT: 109,
	NUMPAD_ADD: 107,
	PLUS: 187,
	LETTER_E: 69,
	NUMPAD_MINUS: 109,
	LETTER_A: 65,
	LETTER_Z: 90,
	ENTER: 13,
	SPACE: 32,
	BACK_SPACE: 8,
	LEFT_ARROW: 37,
	UP_ARROW: 38,
	RIGHT_ARROW: 39,
	DOWN_ARROW: 40,
};

export const MediaInputs = {
	AUDIO_INPUT: 'audioinput',
	VIDEO_INPUT: 'videoinput',
};

export const RequestType = {
	RAPID: 1,
	ADMISSION: 2,
	REGULAR: 3,
};

export const UserTypes = {
	DOCTOR: 1,
	NURSE: 2,
	PATIENT: 3,
	ADMIN: 4,
	SUPER_USER: 5,
	SUPER_ADMIN: 6,
	OWNER: 7,
	DIGITAL_CLINICIAN: 8,
	VISITOR: 9,
	VIRTUAL_SITTER: 10,
	ROOM_SIGN: 97,
	SUPPORT: 98,
	GUEST: 99,
};

export const SocketState = {
	CONNECTED: {
		type: 0,
		message: 'Connected',
	},
	RECONNECTING: {
		type: 1,
		message: 'Reconnecting...',
	},
	DISCONNECTED: {
		type: 2,
		message: 'Connection is lost',
	},
};

export const HelloSettings = {
	SELECT_MODE: 1,
	AUTO_ANSWER: 2,
	LANGUAGE: 3,
	PRIVACY_MODE: 4,
	BABY_DETECTION: 5,
	BED_AND_RAILS: 6,
	SKELETON_FALL_DETECTION: 7,
	OBJECT_DETECTION: 8,
	PATIENT_DETECTION: 9,
	SURGICAL_TOOLS_DETECTION: 11,
	AI_MODELS: 12,
	SOFTWARE_UPDATE: 100,
	DATA_ACQUISITION: 13,
	AUDIO_LEVEL: 25,
	CAMERA_TYPE: 15,
	PORTRAIT_MODE: 17,
	DEVICE_ORIENTATION: 26,
	HDMI_SYSDELAY: 21,
	PRIVACY_MODE_TIMEOUT: 24,
	AUDIO_SETTINGS: 999,
	ASPECT_RATIO: 23,
};

export const DeviceStateNames = {
	HasPairedRemote: 'hasPairedRemote',
};

export const EventSubscriberRoomType = {
	AI_EVENTS: 7,
	USER_STATUS: 8,
	DEVICE_STATUS: 9,
	PATIENT_EVENTS: 10,
	NURSE_STATION_EVENTS: 11,
};

export const MeasureDeviceType = {
	VITAL_KIT: 'vitalKit',
	WATCH: 'watch',
	AI_DETECTIONS: 'aiDetection',
	IOT_DEVICES: 'iHealth',
};

export const StreamError = {
	SCREEN_SHARE_CANCELED: {
		type: 0,
		message: 'Screen share canceled',
	},
	CANT_ACCESS_MEDIA_STREAM: {
		type: 1,
		message: `Can't access media stream`,
	},
	BROWSER_NOT_SUPPORTED: {
		type: 2,
		message: 'Browser not supported',
	},
	EXTENSION_NOT_INSTALLED: {
		type: 3,
		message: 'Extension not installed',
	},
	DEVICE_NOT_FOUND: {
		type: 4,
		message: 'Device not found',
	},
	STREAM_NOT_ALLOWED: {
		type: 5,
		message: 'Stream not allowed',
	},
	PERMISSION_DISMISSED: {
		type: 6,
		message: 'Permission dismissed',
	},
	NOT_READABLE_ERROR: {
		type: 7,
		message: 'Not readable error',
	},
	ONLY_SCREEN_FOUND: {
		type: 8,
		message: 'Only screen found',
	},
	SCREEN_SHARE_NOT_SUPPORTED: {
		type: 9,
		message: 'Screen share not supported',
	},
	CAMERA_BLOCKED: {
		type: 10,
		message: 'Camera permission is blocked',
	},
	MICROPHONE_BLOCKED: {
		type: 11,
		message: 'Microphone permission is blocked',
	},
	CAM_AND_MIC_BLOCKED: {
		type: 12,
		message: 'Camera and microphone permission is blocked',
	},
	MICROPHONE_NOT_FOUND: {
		type: 13,
		message: 'Microphone not found',
	},
	CAMERA_NOT_FOUND: {
		type: 14,
		message: 'Camera not found',
	},
	MICROPHONE_BLOCKED_GENERIC: {
		type: 15,
		message: 'Microphone permission is blocked',
	},
	CAMERA_BLOCKED_GENERIC: {
		type: 16,
		message: 'Camera permission is blocked',
	},
};

export const ConferenceEndReason = {
	UNDEFINED: 0,
	PARTICIPANT_OFFLINE: 1,
	PARTICIPANT_BUSY: 2,
	INITIATOR_LEFT: 3,
	PARTICIPANT_NOT_ANSWERING: 4,
	PARTICIPANT_DECLINED: 5,
	PARTICIPANT_LEFT: 6,
	OWNER_LEFT: 7,
	ABORTED: 8,
	DROPPED: 9,
	PARTICIPANT_PASSWORD_CHANGED: 10,
	PARTICIPANT_IDLE: 11,
	TERMINATED_BY_ADMINISTRATOR: 12,
	PARTICIPANT_INVITE_DENIED: 13,
	PARTICIPANT_INVITE_FAILED: 14,
	FAILED_TO_GET_INITIATOR_INFO: 991,
	REMOVED: 992,
	UNAUTHORIZED: 993,
	E2EE_FAILED: 994,
	ANSWERED_ELSEWHERE: 995,
	TRANSFERRED_TO_ANOTHER_CLIENT: 996,
	HAS_ACTIVE_CONFERENCE: 997,
	INIATOR_BUSY: 998,
	CLEANUP: 999,
};

export const RTCPeerConnectionEnum = {
	CONNECTION_STATE: {
		NEW: 'new',
		CONNECTING: 'connecting',
		CONNECTED: 'connected',
		DISCONNECTED: 'disconnected',
		FAILED: 'failed',
		CLOSED: 'closed',
	},
	SIGNALING_STATE: {
		STABLE: 'stable',
		HAVE_LOCAL_OFFER: 'have-local-offer',
		HAVE_REMOTE_OFFER: 'have-remote-offer',
		HAVE_LOCAL_PRANSWER: 'have-local-pranswer',
		HAVE_REMOTE_PRANSWER: 'have-remote-pranswer',
		CLOSED: 'closed',
	},
	ICE_CONNECTION_STATE: {
		NEW: 'new',
		CHECKING: 'checking',
		CONNECTED: 'connected',
		COMPLETED: 'completed',
		FAILED: 'failed',
		DISCONNECTED: 'disconnected',
		CLOSED: 'closed',
	},
	ICE_GATHERING_STATE: {
		NEW: 'new',
		GATHERING: 'gathering',
		COMPLETE: 'complete',
	},
};

export const DeviceCommands = {
	REBOOT: 0,
	SHUTDOWN: 1,
	ENABLE_METRICS: 2,
	FACTORY_RESET: 3,
	FORCE_UPDATE: 4,
	PULL_LOGS: 5,
	REBOOT_HUDDLE_CAM: 6,
	SWITCH_AUDIO_OUTPUT: 7 /* We don't care about this for now */,
	PAIR_UNAPIR_REMOTE: 8,
	CLEAR_DATA: 9,
	REQUEST_ALLOWLIST: 10,
};

export const JoinConferenceFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'joinConferenceFailureReason.unhandledException',
	NULL_CONFERENCE: 'joinConferenceFailureReason.nullConference',
	WRONG_PARTICIPANT_STATE: 'joinConferenceFailureReason.wrongParticipantState',
	FAILED_TO_GET_PARTICIPANT_INFO: 'joinConferenceFailureReason.failedToGetParticipantInfo',
};

export const StreamTypes = {
	AUDIO: 1,
	VIDEO: 2,
	SCREEN_SHARE: 3,
};

export const ObjectType = {
	USER: 0,
	HELLO_DEVICE: 1,
	COMPANION_DEVICE: 2,
	COMPANY: 3,
	SYSTEM: 5,
	SIP_USER: 999,
};

export const AudioOutputDevice = {
	HELLO: 0,
	TV: 1,
};

export const TerminateRequestFailureReasonEnum = {
	UNHANDLED_EXCEPTION: 'terminateRequestFailureReason.unhandledException',
	NULL_CONFERENCE: 'terminateRequestFailureReason.nullConference',
	TERMINATE_IN_PROGRESS: 'terminateRequestFailureReason.terminateInProgress',
	NULL_TERMINATE_REQUEST: 'terminateRequestFailureReason.nullTerminateRequest',
	NULL_CONNECTED_USER_PARTICIPANT: 'terminateRequestFailureReason.nullConnectedUserParticipant',
	NO_PERMISSION: 'terminateRequestFailureReason.noPermission',
};

export const CallStatsLogType = {
	INFORMATION: 1,
	WARNING: 2,
	ERROR: 3,
};

export const ConferenceErrorType = {
	SIGNALING_ERROR: 0, // failures or errors during the signaling procedure
	MEDIA_SOURCE_ERROR: 1, // failure to get access to media device (mic, camera, screen-capture)
	SDP_GENERATION_ERROR: 2, // SDP generation error occur either when generating an offer or answer with incorrect configuration parameters
	NEGOTIATION_FAILURE: 3, // negotiation errors during SDP offer/answer, they mainly occur due ti differing media and network configuration between endpoints
	ICE_CONNECTION_FAILURE: 4, // ICE failures occur when NAT/firewall traversal fails
	TRANSPORT_FAILURE: 5, // call drops after the connection establishment
};

export const HarkEvents = {
	STOPPED_SPEAKING: 'stopped_speaking',
	VOLUME_CHANGE: 'volume_change',
};

export const MediaPermissions = {
	DENIED: 'denied',
	GRANTED: 'granted',
	PROMPT: 'prompt',
};

export const MediaTypes = {
	CAMERA: 'camera',
	MICROPHONE: 'microphone',
};

export const LedColors = {
	RED: 'red',
	GREEN: 'green',
	LIGHTGREEN: 'lightgreen',
	BLUE: 'blue',
	LIGHTBLUE: 'lightblue',
	ORANGE: 'orange',
	YELLOW: 'yellow',
	DARKYELLOW: 'dark-yellow',
	STRONGYELLOW: 'strong-yellow',
	WHITE: 'white',
	PURPLE: 'purple',
	LIGHTPURPLE: 'light-purple',
	PINK: 'pink',
	OFF: 'off',
	ON: 'on',
};

export const HealthcareErrorCode = {
	USER_NOT_FOUND: 4001,
	MONITORING_SESSION_NOT_FOUND: 4002,
	POOL_ROLE_NOT_FOUND: 4003,
	POOL_NOT_FOUND: 4004,
	NURSE_ASSIGNMENT_NOT_FOUND: 4005,
	RESOURCE_EXISTS: 4006,
	DEVICE_POOL_NOT_FOUND: 4007,
	INSUFFICIENT_PERMISSIONS: 4008,
	REQUEST_IS_ASSIGNED: 4009,
	INVALID_USER_PREFERENCE_TYPE: 4010,
	GENERAL_NURSE_NOT_FOUND: 4011,
	APPOINTMENT_SLOT_NOT_FOUND: 4012,
	USER_ROLES_NOT_FOUND: 4013,
	NO_NURSE_AUTHORIZED: 4014,
	PATIENT_NOT_FOUND: 4015,
	USER_DEACTIVATED: 4016,
	PATIENT_EXISTING_PENDING_REQUEST: 4017,
	PATIENT_EXISTING_APPOINTMENT_TIMESLOT: 4018,
	DEVICE_OFFLINE: 4019,
	DEVICE_NOT_AVAILABLE_FOR_CALL: 4020,
	DEVICE_STATE_NOT_AVAILABLE: 4021,
	USER_IS_CHECKED_IN: 4024,
	DOCTOR_ID_CARD_EXISTS: 4025,
	PATIENT_IN_QUEUE: 4026,
	MEDICAL_VISIT_INITIATED: 4029,
	PATIENT_IN_VISIT: 4030,
	EXISTING_COMPANION_MODE: 5001,
	MAX_AMBIENT_MONITORING_PROVIDERS: 8005,
};

export const PrecautionTriggerValues = {
	FALL_RISK_SCALE: 'ED Fall Risk Assessment Tool',
	IBED_FALL_RISK: 'YES',
	PED_FALL_RISK: 'High Fall Risk',
};

export const ConditionType = {
	FALL_RISK: 1,
	DIABETES: 2,
	PRECAUTION: 3,
	ISOLATIONS: 4,
	PRIMARY_PATIENT_CONDITION: 8,
};

export const AlertTypes = {
	DANGER: 'danger',
	WARNING: 'warning',
	SUCCESS: 'success',
	INFORMATION: 'information',
};

export const AiAlertActivityType = {
	AI_ALERT_SENT: 1,
	AI_ALERT_RECEIVED: 2,
	ADDED_AS_HOSPITAL_CARETAKER: 3,
	COMMENTED: 4,
	ACKNOWLEDGED: 5,
	AI_FAKE_ALERT: 6,
	AI_FORWARD_TO_NURSES: 7,
};

export const RiskLevels = {
	HIGH: 'high',
	MODERATE: 'mod',
	LOW: 'low',
};
export const LastConditionTypes = [
	{ code: '10100001', text: 'universalSafetyInterventions', risk: RiskLevels.LOW },
	{ code: '10100002', text: 'fallRisk', risk: RiskLevels.MODERATE },
	{ code: '10100003', text: 'silentHeartAttack', risk: RiskLevels.HIGH },
	{ code: '10100004', text: 'fallHarmRisk', risk: RiskLevels.HIGH },
];

export const CompanyRoleObjects = [
	{ id: CompanyRoles.DOCTOR, role: UserRoles.DOCTOR },
	{ id: CompanyRoles.VIRTUAL_CARE_PROVIDER, role: UserRoles.NURSE },
	{ id: CompanyRoles.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: CompanyRoles.PATIENT, role: UserRoles.PATIENT },
	{ id: CompanyRoles.ADMIN, role: UserRoles.ADMIN },
	{ id: CompanyRoles.SUPER_USER, role: UserRoles.SUPER_USER },
	{ id: CompanyRoles.SUPER_ADMIN, role: UserRoles.SUPER_ADMIN },
	{ id: CompanyRoles.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: CompanyRoles.VIRTUAL_SITTER, role: UserRoles.VIRTUAL_SITTER },
	{ id: CompanyRoles.VISITOR, role: UserRoles.VISITOR },
	{ id: CompanyRoles.GUEST, role: UserRoles.GUEST },
];

export const Roles = [
	{ id: UserTypes.DOCTOR, role: UserRoles.DOCTOR },
	{ id: UserTypes.NURSE, role: UserRoles.NURSE },
	{ id: UserTypes.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: UserTypes.PATIENT, role: UserRoles.PATIENT },
	{ id: UserTypes.ADMIN, role: UserRoles.ADMIN },
	{ id: UserTypes.SUPER_USER, role: UserRoles.SUPER_USER },
	{ id: UserTypes.SUPER_ADMIN, role: UserRoles.SUPER_ADMIN },
	{ id: UserTypes.OWNER, role: UserRoles.OWNER },
	{ id: UserTypes.DIGITAL_CLINICIAN, role: UserRoles.DIGITAL_CLINICIAN },
	{ id: UserTypes.VIRTUAL_SITTER, role: UserRoles.VIRTUAL_SITTER },
	{ id: UserTypes.VISITOR, role: UserRoles.VISITOR },
	{ id: UserTypes.GUEST, role: UserRoles.GUEST },
];
