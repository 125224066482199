import { Log, UserManager, WebStorageStateStore } from 'oidc-client';
import Cookie from 'universal-cookie';
import { IDENTITY_CONFIG, METADATA_OIDC } from 'constants/auth-constants.js';
import { gatewayApi } from 'constants/global-variables.js';
// @ts-ignore
import { Roles, UserRoles } from 'constants/enums.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';

// GetStorage() keys
const USER_INFO = 'userProfile';
const USER_ID = 'userId';
const USER_ROLE = 'userRole';
const COMPANY_ID = 'companyId';

Log.logger = console;
// @ts-ignore
Log.level = Log.DEBUG;
let storageType = window !== window.parent ? 'sessionStorage' : 'localStorage';
const currentUrlParams = new URL(window.location.href).searchParams;
const hasRefToken = currentUrlParams.get('refToken') || getStorage().getItem('ref_token');
if (
	currentUrlParams &&
	((currentUrlParams.get('email') && currentUrlParams.get('ts') && currentUrlParams.get('s')) || hasRefToken)
) {
	storageType = 'sessionStorage';
}

const userManager = new UserManager({
	...IDENTITY_CONFIG,
	userStore: new WebStorageStateStore({ store: window[storageType] }),
	metadata: {
		...METADATA_OIDC,
	},
});

const userLoadedEvent = user => {
	getStorage().setItem('access_token', user.access_token);
	getStorage().setItem('id_token', user.id_token);
	setUser({}, {});
	if (
		window.location.href.indexOf('signin-oidc') === -1 ||
		(sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST && !getStorage().getItem('ref_token'))
	) {
		return;
	}

	navigateToScreen();
};

const silentRenewErrorEvent = e => {
	Log.logger.warn('silent renew error', e.message);
};

const accessTokenExpiredEvent = () => {
	Log.logger.warn('token expired');
	signinSilent();
};

const accessTokenExpiringEvent = () => {
	Log.logger.warn('token expiring');
	signinSilent();
};

userManager.events.addUserLoaded(userLoadedEvent);
userManager.events.addSilentRenewError(silentRenewErrorEvent);
userManager.events.addAccessTokenExpired(accessTokenExpiredEvent);
userManager.events.addAccessTokenExpiring(accessTokenExpiringEvent);

export function unbindUserManagerEvents() {
	userManager.events.removeUserLoaded(userLoadedEvent);
	userManager.events.removeSilentRenewError(silentRenewErrorEvent);
	userManager.events.removeAccessTokenExpired(accessTokenExpiredEvent);
	userManager.events.removeAccessTokenExpiring(accessTokenExpiringEvent);
	userManager.events.unload();
}

export function signinRedirectCallback() {
	userManager.signinRedirectCallback().catch(() => {
		userManager.clearStaleState().then(() => {
			if (sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST && !getStorage().getItem('ref_token')) {
				return;
			}

			navigateToScreen();
		});
	});
}

export function logout() {
	userManager.signoutRedirect({
		id_token_hint: getStorage().getItem('id_token'),
	});
	userManager.clearStaleState();
}

export function signoutRedirectCallback() {
	userManager.signoutRedirectCallback().then(() => {
		if (sessionStorage.getItem(USER_ROLE) !== UserRoles.GUEST) {
			const cookiePreferences = getStorage().getItem('cookiePreferences');
			const cookieAnswer = getStorage().getItem('cookieAnswer');
			getStorage().clear();

			getStorage().setItem('cookiePreferences', cookiePreferences);
			getStorage().setItem('cookieAnswer', cookieAnswer);
			window.location.replace(IDENTITY_CONFIG.public_uri);
		}
	});
	userManager.clearStaleState();
}

export function isAuthenticated() {
	const accessToken =
		sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST
			? sessionStorage.getItem('access_token')
			: getStorage().getItem('access_token');

	return !!accessToken;
}

export function signinRedirect() {
	const url = new URL(window.location.href);
	const email = url.searchParams.get('email');
	const ts = url.searchParams.get('ts');
	const sid = url.searchParams.get('s');
	if (email || ts) {
		let ehrData = { email, ts };
		if (sid) {
			window.sessionStorage.setItem('ehr.sid', sid);
		}
		completeEHRSignin(ehrData);
		return;
	}

	if (
		document.cookie.split(';').find(x => x.trim().startsWith('extsso=')) ||
		document.cookie.split(';').find(x => x.trim().startsWith('ehrsso='))
	) {
		const cookie = new Cookie();
		cookie.remove('extsso');
		cookie.remove('ehrsso');
		userManager.signinRedirect({
			prompt: 'login',
		});
		return;
	}

	userManager.signinRedirect({});
}

export function completeEHRSignin(ehrData) {
	getStorage().setItem('redirectUri', window.location.pathname);
	const args = {};
	if (ehrData) {
		const { email, ts } = ehrData;
		args.acr_values = `ehr_email:${email} ehr_ts:${ts}`;
		args.prompt = 'login';
	}
	userManager.signinRedirect(args);
}

export function signinRedirectRefToken(refToken = null) {
	getStorage().setItem('redirectUri', window.location.pathname);
	const args = {};
	if (window.location.hash || refToken) {
		if (refToken) {
			args.acr_values = `sol_ref:${refToken}`;
			args.prompt = 'login';
		} else {
			const hash = window.location.hash.substring(1);
			if (hash) {
				const refId = hash.split('=');
				if (refId && refId.length && refId.length === 2) {
					args.acr_values = `sol_ref:${refId[1]}`;
					args.prompt = 'login';
				}
			}
		}
	}
	userManager.signinRedirect(args);
}

export function signinSilentCallback() {
	userManager.signinSilentCallback();
}

export function createSigninRequest() {
	return userManager.createSigninRequest();
}

export async function getUser() {
	const user = await userManager.getUser();
	if (!user) {
		await userManager.signinRedirectCallback();
		return undefined;
	}
	return user;
}

export function getAccessToken() {
	const accessToken =
		sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST
			? sessionStorage.getItem('access_token')
			: getStorage().getItem('access_token');

	if (!accessToken) {
		throw new Error('Attempted to access unloaded access token');
	}

	return accessToken;
}

export function getUserId() {
	const userId =
		sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST ? sessionStorage.getItem(USER_ID) : getStorage().getItem(USER_ID);

	if (!userId) {
		throw new Error('Attempted to access unloaded userId');
	}

	return userId;
}

export function getUserInfo() {
	const userInfo =
		sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST ? sessionStorage.getItem(USER_INFO) : getStorage().getItem(USER_INFO);
	if (!userInfo) {
		throw new Error('Attempted to access unloaded userInfo');
	}

	return JSON.parse(userInfo);
}

export function setUserInfo(newProfile) {
	getStorage().setItem(USER_INFO, JSON.stringify(newProfile));
}

export function getUserRole() {
	const userRole =
		sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST ? sessionStorage.getItem(USER_ROLE) : getStorage().getItem(USER_ROLE);
	if (!userRole) {
		return null;
	}
	if (
		[
			UserRoles.DOCTOR,
			UserRoles.NURSE,
			UserRoles.DIGITAL_CLINICIAN,
			UserRoles.PATIENT,
			UserRoles.ADMIN,
			UserRoles.SUPER_USER,
			UserRoles.SUPER_ADMIN,
			UserRoles.GUEST,
			UserRoles.VIRTUAL_SITTER,
			UserRoles.VISITOR,
		].includes(userRole)
	) {
		return userRole;
	}
	return null;
}

export function getUserRoleId() {
	return Roles.find(x => x.role === getUserRole())?.id;
}

export function parseJwt(token) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
}

function setUser(data, userInfo, userRole) {
	getStorage().setItem('userId', data.sub);
	getStorage().setItem('userProfile', JSON.stringify(userInfo));
}

function navigateToScreen() {
	const redirectUri = getStorage().getItem('redirectUri') ? getStorage().getItem('redirectUri') : '';
	window.location.replace(redirectUri);
}

export function signinSilent() {
	userManager
		.signinSilent({ scope: IDENTITY_CONFIG.scope, response_type: IDENTITY_CONFIG.response_type })
		.then(user => {
			Log.logger.info('signed in', user);
		})
		.catch(err => {
			if (sessionStorage.getItem(USER_ROLE) === UserRoles.GUEST) {
				return;
			}

			if (err.message === 'login_required') {
				Log.logger.info('session on IDSRV has expired a login is required');
				signoutRedirectCallback();
			} else if (err.message === 'Frame window timed out') {
				Log.logger.info('Frame window timed out');
				signoutRedirectCallback();
			}
			Log.logger.error('signinSilent', err);
		});
}

export const getUserProfile = async (userId, companyId) => {
	try {
		const url = companyId
			? `/v1.1/healthcare/organizations/${companyId}/users/${userId}/profile`
			: `/v1.1/healthcare/users/${userId}/profile`;
		const { data } = await gatewayApi.get(url);
		return data.profile;
	} catch (error) {
		return { error: error.response.data };
	}
};

export function isUserInfoLoaded() {
	return getStorage().getItem(USER_INFO) && getStorage().getItem(USER_ID) && getStorage().getItem(USER_ROLE);
}

export function isFitBitAuthenticated() {
	return !!getStorage().getItem('fitbit_token');
}

export const getCompanyId = () => {
	const companyId = getStorage().getItem(COMPANY_ID);
	if (!companyId) {
		return null;
	}
	return companyId;
};

export const setCompanyId = companyId => {
	getStorage().setItem(COMPANY_ID, companyId);
};

export const setHelloDeviceId = deviceId => {
	getStorage().setItem('helloDeviceId', deviceId);
};

export const setDepartmentId = departmentId => {
	getStorage().setItem('departmentId', departmentId);
};

export const setHealthCareUserId = patientId => {
	getStorage().setItem('patientId', patientId);
};

export const clearStorage = () => {
	getStorage().removeItem('helloDeviceId');
	getStorage().removeItem('departmentId');
	getStorage().removeItem('patientId');
	getStorage().removeItem('conversationMembersInfo');
	getStorage().removeItem('IPAddressDenied');
	getStorage().removeItem('isContinueAs');
	getStorage().removeItem('selectedNurses');
};

export const setUserRole = role => getStorage().setItem('userRole', role);

export const isInvalidIPAddress = () => {
	return getStorage().getItem('IPAddressDenied') != null;
};
