export const METADATA_URL = {};
export const METADATA_COMPANY = {};
if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
	METADATA_URL.gatewayApiBasePath = import.meta.env.VITE_GATEWAY_API_BASE_URL;
	METADATA_URL.legacyApiBasePath = import.meta.env.VITE_LEGACY_API_BASE_URL;
	METADATA_URL.localApiBasePath = import.meta.env.VITE_SERVER_PUBLIC_URL;
	METADATA_URL.messengerBasePath = import.meta.env.VITE_MESSENGER_BASE_URL;
	METADATA_URL.signalingUrl = import.meta.env.VITE_SIGNALING_URL;
	METADATA_URL.epicUrl = import.meta.env.VITE_EPIC_URL;
	METADATA_COMPANY.companyId = import.meta.env.VITE_COMPANY_ID;
	METADATA_URL.teamBotApiBasePath = import.meta.env.VITE_TEAM_BOT;
} else {
	METADATA_URL.gatewayApiBasePath = window.__env__.VITE_GATEWAY_API_BASE_URL;
	METADATA_URL.legacyApiBasePath = window.__env__.VITE_LEGACY_API_BASE_URL;
	METADATA_URL.localApiBasePath = window.__env__.VITE_PUBLIC_URL;
	METADATA_URL.messengerBasePath = window.__env__.VITE_MESSENGER_BASE_URL;
	METADATA_URL.signalingUrl = window.__env__.VITE_SIGNALING_URL;
	METADATA_URL.epicUrl = window.__env__.VITE_EPIC_URL;
	METADATA_COMPANY.companyId = window.__env__.VITE_COMPANY_ID;
	METADATA_URL.teamBotApiBasePath = window.__env__.VITE_TEAM_BOT;
}

export const CompanyId = METADATA_COMPANY.companyId || '';
export const GatewayApiBasePath = METADATA_URL.gatewayApiBasePath || 'https://api.dev.solaborate.com';
export const MessengerBasePath = METADATA_URL.messengerBasePath || 'https://messenger.dev.solaborate.com';
export const SignalingServerAddress = METADATA_URL.signalingUrl || 'https://signaling.dev.solaborate.com';
export const FitBitApiBasePath = 'https://api.fitbit.com';
export const ClinicaltablesApiBasePath = 'https://clinicaltables.nlm.nih.gov/api';
export const LegacyApiBasePath = METADATA_URL.legacyApiBasePath || 'https://mobile.dev.solaborate.com';
export const MobileApiBasePath = `${METADATA_URL.legacyApiBasePath}/`;
export const LocalApiBasePath = METADATA_URL.localApiBasePath || 'https://localhost:5001';
export const TeamBotApiBasePath = METADATA_URL.teamBotApiBasePath || 'https://bot.dev.solaborate.com';

export const EpicApiBasePath = METADATA_URL.epicUrl;
