import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ position, content, children = <></> }) => {
	const [showTooltip, setShowTooltip] = useState(false);
	return (
		<div className='tooltip-container' onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
			{children}
			{showTooltip && (
				<div className={`tooltip tooltip-${position}`}>
					<div className='tooltip-arrow'></div>
					<div className='tooltip-content'>{content}</div>
				</div>
			)}
		</div>
	);
};

Tooltip.propTypes = {
	position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

export default Tooltip;
