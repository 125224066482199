import React from 'react';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';

const NSTBrowserWarning = () => (
	<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
		<div className='member-error'>
			<h2>
				<i>{translate('browserNSTWarningTitle')}</i>
			</h2>
			<p>{translate('browserNSTWarningDescription')}</p>
		</div>
	</Grid>
);

export default NSTBrowserWarning;
