import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getTeams = async healthSystemId => {
	try {
		const url = `/v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/frontline-teams`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};
