import { gatewayApi } from 'constants/global-variables.js';

export const subscribeToAlerts = async nurseStationDeviceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/devices/${nurseStationDeviceId}/nurse-station-subscribed-devices/ai-settings`);
		return data;
	} catch (error) {
		return { error };
	}
};
