import { gatewayApi } from 'constants/global-variables.js';
import { getUserId, parseJwt } from 'infrastructure/auth.js';

export const changeUserLanguage = async language => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/users/${getUserId()}/preferences/app-language`, {
			appLanguageValue: language,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateSession = async params => {
	const userId = getUserId();
	try {
		const { data } = await gatewayApi.put(`/v1.1/healthcare/users/${userId}/session`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

const getUserIdFromToken = () => {
	let accessToken = window.Android?.getToken() ?? null;
	if (accessToken) {
		const parsedData = parseJwt(accessToken);
		return parsedData.sub;
	}
};

export const getUserPreferences = async (preferenceTypeId, roomId) => {
	try {
		const url = `/v1/healthcare/users/${getUserIdFromToken()}/preferences?${
			preferenceTypeId ? `preferenceTypeCategoryId=${preferenceTypeId}` : ''
		}${roomId ? `&roomId=${roomId}` : ''} `;
		const { data } = await gatewayApi.get(url);
		return data.preferences;
	} catch (error) {
		return { error };
	}
};
