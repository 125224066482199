import { ConfigurationActionTypes } from 'constants/action-types.js';
import { ConfigurableDigitalBackgroundSettings, getAdminConfigurableMenu } from 'constants/configurationEnums.js';

const initialState = {
	configurableMenu: getAdminConfigurableMenu(true),
	adminConfigurableMenu: getAdminConfigurableMenu(false),
	roleRoundingConfigurations: {},
	userSettings: {},
	healthSystemConfigurations: {},
	unboundHealthSystemConfigurations: {},
	unboundHealthSystemSettings: { visualsSettings: { ...ConfigurableDigitalBackgroundSettings }, callSettings: {} },
};

export const configurations = (state = initialState, action) => {
	switch (action.type) {
		case ConfigurationActionTypes.SET_USER_CONFIGURABLE_MENU:
			return {
				...state,
				configurableMenu: action.payload,
			};
		case ConfigurationActionTypes.SET_ADMIN_CONFIGURABLE_MENU:
			return {
				...state,
				adminConfigurableMenu: action.payload,
			};
		case ConfigurationActionTypes.SET_HEALTH_SYSTEM_CONFIGURATIONS:
			return {
				...state,
				healthSystemConfigurations: { ...state.healthSystemConfigurations, ...action.payload },
			};
		case ConfigurationActionTypes.SET_HEALTH_SYSTEM_UNBOUND_CONFIGURATIONS:
			return {
				...state,
				unboundHealthSystemConfigurations: { ...state.unboundHealthSystemConfigurations, ...action.payload },
			};
		case ConfigurationActionTypes.SET_HEALTH_SYSTEM_UNBOUND_SETTINGS:
			return {
				...state,
				unboundHealthSystemSettings: { ...state.unboundHealthSystemSettings, ...action.payload },
			};
		case ConfigurationActionTypes.SET_USER_SETTINGS:
			return {
				...state,
				userSettings: action.payload,
			};
		case ConfigurationActionTypes.SET_ROLE_ROUNDING_CONFIGURATIONS:
			return {
				...state,
				roleRoundingConfigurations: action.payload,
			};
		default:
			return state;
	}
};
