import { gatewayApi } from 'constants/global-variables.js';

export const addAlertActivity = async ({ alertId, activityType, isAiAlert, comment = null, isDraft = false }) => {
	try {
		const type = isAiAlert ? 'ai' : 'measurement';
		const dataToSend = isAiAlert
			? { aiAlertActivityType: activityType, comment, isDraft }
			: { measurementAlertActivityType: activityType, comment };
		const { data } = await gatewayApi.post(`/v1/alerts/${type}/${alertId}/activities`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const forwardAiAlert = async ({ conversationId, alertId, hospital, department, floor, room }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/alerts/ai/${alertId}/forward`, {
			conversationId,
			hospital,
			department,
			floor,
			room,
		});
		return data;
	} catch (error) {
		return { error };
	}
};
