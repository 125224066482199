import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import { isUserInfoLoaded, getUserRole, isAuthenticated } from 'infrastructure/auth.js';
import { UserRoles } from 'constants/enums.js';
import { isUserUnauthorized, isContinueAsSelected, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import AlertCenter from 'views/AlertCenterMayo.jsx';

const isRoleAuthorized = routeRoles => {
	return !routeRoles || routeRoles.includes(getUserRole());
};

const PrivateRoute = ({ component, ...rest }) => {
	const user = useSelector(state => state.user);
	const healthSystems = useSelector(state => state.healthSystems);
	const integrationId = getStorage().getItem('iframe_integration_id');

	const showUnAuthorized = () =>
		((!isRoleAuthorized(rest.roles) || !isUserInfoLoaded()) && healthSystems.isHealthSystemFetched) || isUserUnauthorized();

	const notAssignedOnAHSRole = [UserRoles.PATIENT, UserRoles.VISITOR].includes(getUserRole());

	const renderFn = Component => props => {
		if (!!Component && isAuthenticated()) {
			if (showUnAuthorized()) {
				return <AlertCenter />;
			}

			if (healthSystems.isHealthSystemFetched || notAssignedOnAHSRole) {
				return <Component {...props} />;
			}

			return <Component {...props} />;
		}
		return (
			<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
				<div style={{ textAlign: 'center' }}>
					<Loader />
				</div>
			</Grid>
		);
	};

	return <Route render={renderFn(component)} {...rest} />;
};

export default PrivateRoute;
