export const IDENTITY_CONFIG = {};
// IDENTITY_CONFIG.audience= 'https=//banyanmed.com';
IDENTITY_CONFIG.automaticSilentRenew = true;
IDENTITY_CONFIG.loadUserInfo = false;
IDENTITY_CONFIG.response_type = 'code';
IDENTITY_CONFIG.filterProtocolClaims = true;
IDENTITY_CONFIG.scope = 'openid profile email sol.user api.gateway API';

if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
	IDENTITY_CONFIG.authority = import.meta.env.VITE_IDSRV_URL;
	IDENTITY_CONFIG.client_id = import.meta.env.VITE_IDSRV_CLIENT_ID;
	IDENTITY_CONFIG.client_secret = import.meta.env.VITE_IDSRV_CLIENT_SECRET;
	IDENTITY_CONFIG.redirect_uri = import.meta.env.VITE_IDSRV_REDIRECT_URL;
	IDENTITY_CONFIG.login = `${import.meta.env.VITE_IDSRV_URL}/login`;
	IDENTITY_CONFIG.silent_redirect_uri = import.meta.env.VITE_SILENT_REDIRECT_URL;
	IDENTITY_CONFIG.post_logout_redirect_uri = import.meta.env.VITE_LOGOUT_URL;
	IDENTITY_CONFIG.public_uri = `${import.meta.env.VITE_PUBLIC_URL}/`;
	IDENTITY_CONFIG.fitbit_client_id = import.meta.env.VITE_FITBIT_CLIENT_ID;
} else {
	IDENTITY_CONFIG.authority = window.__env__.VITE_IDSRV_URL;
	IDENTITY_CONFIG.client_id = window.__env__.VITE_IDSRV_CLIENT_ID;
	IDENTITY_CONFIG.client_secret = window.__env__.VITE_IDSRV_CLIENT_SECRET;
	IDENTITY_CONFIG.redirect_uri = window.__env__.VITE_IDSRV_REDIRECT_URL;
	IDENTITY_CONFIG.login = `${window.__env__.VITE_IDSRV_URL}/login`;
	IDENTITY_CONFIG.silent_redirect_uri = window.__env__.VITE_SILENT_REDIRECT_URL;
	IDENTITY_CONFIG.post_logout_redirect_uri = window.__env__.VITE_LOGOUT_URL;
	IDENTITY_CONFIG.public_uri = window.__env__.VITE_PUBLIC_URL;
	IDENTITY_CONFIG.fitbit_client_id = window.__env__.VITE_FITBIT_CLIENT_ID;
}

export const METADATA_OIDC = {};
if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
	METADATA_OIDC.issuer = import.meta.env.VITE_IDSRV_URL;
	METADATA_OIDC.jwks_uri = `${import.meta.env.VITE_IDSRV_URL}/.well-known/jwks`;
	METADATA_OIDC.authorization_endpoint = `${import.meta.env.VITE_IDSRV_URL}/connect/authorize`;
	METADATA_OIDC.token_endpoint = `${import.meta.env.VITE_IDSRV_URL}/connect/token`;
	METADATA_OIDC.userinfo_endpoint = `${import.meta.env.VITE_IDSRV_URL}/connect/userinfo`;
	METADATA_OIDC.end_session_endpoint = `${import.meta.env.VITE_IDSRV_URL}/connect/endsession`;
	METADATA_OIDC.check_session_iframe = `${import.meta.env.VITE_IDSRV_URL}/connect/checksession`;
	METADATA_OIDC.revocation_endpoint = `${import.meta.env.VITE_IDSRV_URL}/connect/revocation`;
	METADATA_OIDC.introspection_endpoint = `${import.meta.env.VITE_IDSRV_URL}/connect/introspect`;
} else {
	METADATA_OIDC.issuer = window.__env__.VITE_IDSRV_URL;
	METADATA_OIDC.jwks_uri = `${window.__env__.VITE_IDSRV_URL}/.well-known/jwks`;
	METADATA_OIDC.authorization_endpoint = `${window.__env__.VITE_IDSRV_URL}/connect/authorize`;
	METADATA_OIDC.token_endpoint = `${window.__env__.VITE_IDSRV_URL}/connect/token`;
	METADATA_OIDC.userinfo_endpoint = `${window.__env__.VITE_IDSRV_URL}/connect/userinfo`;
	METADATA_OIDC.end_session_endpoint = `${window.__env__.VITE_IDSRV_URL}/connect/endsession`;
	METADATA_OIDC.check_session_iframe = `${window.__env__.VITE_IDSRV_URL}/connect/checksession`;
	METADATA_OIDC.revocation_endpoint = `${window.__env__.VITE_IDSRV_URL}/connect/revocation`;
	METADATA_OIDC.introspection_endpoint = `${window.__env__.VITE_IDSRV_URL}/connect/introspect`;
}
