import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Callback from 'components/auth/callback.jsx';
import Logout from 'components/auth/logout.jsx';
import LogoutCallback from 'components/auth/logoutCallback.jsx';
import PrivateRoute from 'routes/privateRoute.jsx';
import SilentRenew from 'components/auth/silentRenew.jsx';
import AlertCenterMayo from 'views/AlertCenterMayo.jsx';

const DefaultRoutes = (
	<Switch>
		<Route exact={true} path='/signin-oidc' component={Callback} />
		<Route exact={true} path='/logout' component={Logout} />
		<Route exact={true} path='/logout/callback' component={LogoutCallback} />
		<Route exact={true} path='/silent-renew' component={SilentRenew} />
		<Route exact={true} path='/' component={AlertCenterMayo} />
		<PrivateRoute path='/care-notifications' component={AlertCenterMayo} />
	</Switch>
);

export default DefaultRoutes;
