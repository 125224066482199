import { gatewayApi } from 'constants/global-variables.js';

export const getDeviceList = async (companyId, level, id) => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${companyId}/level/${level}/id/${id}/devices`);
		return data.devices;
	} catch (error) {
		return { error };
	}
};
