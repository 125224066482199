import React from 'react';

class AndroidInterface extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentConnectionState: null,
		};
	}

	emit(event, ...args) {
		return window.Android?.emit(event, args);
	}

	emitWithAck(event, fn, args) {
		return window.Android?.emitWithAck(event, fn, args);
	}

	on(event, fn) {
		return window.Android?.on(event, fn);
	}

	off(event) {
		return window.Android?.off(event);
	}

	getToken() {
		return window.Android?.getToken();
	}

	getDeviceInfo() {
		return window.Android?.getDeviceInfo();
	}

	getDeviceConnectionState() {
		if (!window.Android) {
			return {};
		}
		const deviceInfo = JSON.parse(window.Android?.getDeviceInfo());
		const connectionState = deviceInfo.connectionState;

		return connectionState;
	}

	componentDidMount() {
		if (!window.Android) {
			return;
		}
		const initialConnectionState = this.getDeviceConnectionState();
		this.setState({ currentConnectionState: initialConnectionState });
		this.props.onConnectionStateChange(initialConnectionState);
		window.Android.connectionStateChanged = state => this.handleConnectionStateChange(state);
	}

	handleConnectionStateChange = newConnectionState => {
		this.setState({ currentConnectionState: newConnectionState });
		this.props.onConnectionStateChange(newConnectionState);
	};

	render() {
		const { children } = this.props;
		return <>{children}</>;
	}
}

export default AndroidInterface;
