import { combineReducers } from 'redux';
import { healthSystems } from 'state/healthSystems/reducer.js';
import { user } from 'state/user/reducer.js';
import { aiSettingsList } from 'state/aiSettings/reducer.js';
import { configurations } from 'state/configurations/reducer.js';
import { language } from 'state/language/reducer.js';
import { devices } from 'state/devices/reducer.js';

export default combineReducers({
	healthSystems,
	user,
	aiSettingsList,
	configurations,
	language,
	devices,
});
