import { ConditionType, ObservationType } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { PrecautionsObservationCodes } from 'constants/precautions.js';

export const getPrecautions = async ownerId => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/patients/${ownerId}/conditions?conditionType=${ConditionType.PRECAUTION}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPrecautions = async (ownerId, dataToSend) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/patients/${ownerId}/conditions`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPrecautionObservations = async (ownerId, encounterId = '') => {
	const queryParams = new URLSearchParams({ ...(encounterId && { 'encounter-id': encounterId }) });
	const types = [ObservationType.BRADEN_SCORE, ObservationType.BEHAVIORAL_SAFETY_PLAN];
	const codes = [
		PrecautionsObservationCodes.BEHAVIORAL_CODE,
		PrecautionsObservationCodes.FALLS_CODE,
		PrecautionsObservationCodes.IBED_FALL_RISK_CODE,
		PrecautionsObservationCodes.FALL_RISK_SCALE_CODE,
		PrecautionsObservationCodes.FALL_RISK_SCORE_CODE,
		PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE,
	];
	types.forEach(type => queryParams.append('ObservationType', type.toString()));
	codes.forEach(type => queryParams.append('Code', type.toString()));
	try {
		const { data } = await gatewayApi.get(`v1.1/healthcare/patients/${ownerId}/observations?${queryParams.toString()}`);
		return data;
	} catch (error) {
		return { error };
	}
};
