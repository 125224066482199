import axios from 'axios';
import {
	ClinicaltablesApiBasePath,
	FitBitApiBasePath,
	GatewayApiBasePath,
	LegacyApiBasePath,
	MobileApiBasePath,
	TeamBotApiBasePath,
} from 'constants/config.js';
import { getAccessToken, signinSilent } from 'infrastructure/auth.js';
import { encounterId } from 'api/whiteboard.js';

export const ClientType = 0;
export const AppType = 4;
export const APP_CONFIG = { URL: {} };

if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
	APP_CONFIG.URL.gatewayApiBasePath = import.meta.env.VITE_GATEWAY_API_BASE_URL;
	APP_CONFIG.URL.legacyApiBasePath = import.meta.env.VITE_LEGACY_API_BASE_URL;
	APP_CONFIG.URL.localApiBasePath = import.meta.env.VITE_SERVER_PUBLIC_URL;
	APP_CONFIG.URL.messengerBasePath = import.meta.env.VITE_MESSENGER_BASE_URL;
	APP_CONFIG.companyId = import.meta.env.VITE_COMPANY_ID;
	APP_CONFIG.defaultPicUrl = import.meta.env.VITE_DEFAULT_PROFILEPIC_URL;
	APP_CONFIG.profilePicBaseUrl = import.meta.env.VITE_PROFILEPIC_BASEURL;
	APP_CONFIG.companyLogoBaseUrl = import.meta.env.VITE_COMPANY_LOGO_BASEURL;
	APP_CONFIG.mediaCdnBaseUrl = import.meta.env.VITE_MEDIA_CDN_BASEURL;
	APP_CONFIG.URL.syncApiUrl = import.meta.env.VITE_SYNC_API_URL;
	APP_CONFIG.buildNumber = import.meta.env.VITE_BUILD_NUMBER;
	APP_CONFIG.releaseName = import.meta.env.VITE_RELEASE_NAME;
	APP_CONFIG.useCallStats = import.meta.env.VITE_USE_CALLSTATS === 'true';
	APP_CONFIG.sendCallStatsOnMonitoring = import.meta.env.VITE_SEND_CALLSTATS_ON_MONITORING === 'true';
	APP_CONFIG.sendCallStatsInterval = import.meta.env.VITE_SEND_CALLSTATS_INTERVAL;
	APP_CONFIG.googleRecaptchaKey = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY;
	APP_CONFIG.mobileAppUriScheme = import.meta.env.VITE_MOBILE_URI_SCHEME;
	APP_CONFIG.googleMapsKey = import.meta.env.VITE_GOOGLE_MAPS_KEY;
	APP_CONFIG.URL.teamBotApiBasePath = import.meta.env.VITE_TEAM_BOT;
	APP_CONFIG.versionCheckInterval = import.meta.env.VITE_VERSION_CHECK_INTERVAL;
	APP_CONFIG.useFeaturePhysicalExercises = import.meta.env.VITE_USE_FEATURE_PHYSICAL_EXERCISES;
	APP_CONFIG.cdnURL = import.meta.env.VITE_CDN_URL;
	APP_CONFIG.reCaptchaSiteKey = import.meta.env.VITE_RECAPTCHASETTINGS_SITEKEY;
	APP_CONFIG.reCaptchaSecretKey = import.meta.env.VITE_RECAPTCHASETTINGS_SECRETKEY;
	APP_CONFIG.showReduxLogs = import.meta.env.VITE_SHOW_REDUX_LOGS === 'true';
	APP_CONFIG.blobStorageUrl = import.meta.env.VITE_MEDIA_STORAGE_URL;
	APP_CONFIG.speechKey = import.meta.env.SPEECH_KEY;
	APP_CONFIG.speechRegion = import.meta.env.SPEECH_REGION;
	APP_CONFIG.epicAdventHSId = import.meta.env.VITE_EPIC_ADVENT_HS_ID;
	APP_CONFIG.showReleaseVersion = import.meta.env.VITE_SHOW_RELEASE_VERSION === 'true';
	APP_CONFIG.amwellTenantId = import.meta.env.VITE_AMWELL_TENANT_ID;
	APP_CONFIG.banyanTenantId = import.meta.env.VITE_BANYAN_TENANT_ID;
	APP_CONFIG.speechToTextKey = import.meta.env.VITE_SPEECH_TO_TEXT_KEY;
	APP_CONFIG.speechToTextRegion = import.meta.env.VITE_SPEECH_TO_TEXT_REGION;
	APP_CONFIG.lifeSignalsUrl = import.meta.env.VITE_LIFE_SIGNALS_URL;
} else {
	APP_CONFIG.URL.gatewayApiBasePath = window.__env__.VITE_GATEWAY_API_BASE_URL;
	APP_CONFIG.URL.legacyApiBasePath = window.__env__.VITE_LEGACY_API_BASE_URL;
	APP_CONFIG.URL.localApiBasePath = window.__env__.VITE_PUBLIC_URL;
	APP_CONFIG.URL.messengerBasePath = window.__env__.VITE_MESSENGER_BASE_URL;
	APP_CONFIG.companyId = window.__env__.VITE_COMPANY_ID;
	APP_CONFIG.defaultPicUrl = window.__env__.VITE_DEFAULT_PROFILEPIC_URL;
	APP_CONFIG.profilePicBaseUrl = window.__env__.VITE_PROFILEPIC_BASEURL;
	APP_CONFIG.companyLogoBaseUrl = window.__env__.VITE_COMPANY_LOGO_BASEURL;
	APP_CONFIG.mediaCdnBaseUrl = window.__env__.VITE_MEDIA_CDN_BASEURL;
	APP_CONFIG.URL.syncApiUrl = window.__env__.VITE_SYNC_API_URL;
	APP_CONFIG.buildNumber = window.__env__.VITE_BUILD_NUMBER;
	APP_CONFIG.releaseName = window.__env__.VITE_RELEASE_NAME;
	APP_CONFIG.useCallStats = window.__env__.VITE_USE_CALLSTATS === 'true';
	APP_CONFIG.sendCallStatsOnMonitoring = window.__env__.VITE_SEND_CALLSTATS_ON_MONITORING === 'true';
	APP_CONFIG.sendCallStatsInterval = window.__env__.VITE_SEND_CALLSTATS_INTERVAL;
	APP_CONFIG.googleRecaptchaKey = window.__env__.VITE_GOOGLE_RECAPTCHA_KEY;
	APP_CONFIG.mobileAppUriScheme = window.__env__.VITE_MOBILE_URI_SCHEME;
	APP_CONFIG.googleMapsKey = window.__env__.VITE_GOOGLE_MAPS_KEY;
	APP_CONFIG.teamBotApiBasePath = window.__env__.VITE_TEAM_BOT;
	APP_CONFIG.versionCheckInterval = window.__env__.VITE_VERSION_CHECK_INTERVAL;
	APP_CONFIG.useFeaturePhysicalExercises = window.__env__.VITE_USE_FEATURE_PHYSICAL_EXERCISES;
	APP_CONFIG.cdnURL = window.__env__.VITE_CDN_URL;
	APP_CONFIG.reCaptchaSiteKey = window.__env__.VITE_RECAPTCHASETTINGS_SITEKEY;
	APP_CONFIG.reCaptchaSecretKey = window.__env__.VITE_RECAPTCHASETTINGS_SECRETKEY;
	APP_CONFIG.showReduxLogs = window.__env__.VITE_HIDE_REDUX_LOGS === 'true';
	APP_CONFIG.blobStorageUrl = window.__env__.VITE_MEDIA_STORAGE_URL;
	APP_CONFIG.helpDeskId = window.__env__.VITE_HELP_DESK_ID;
	APP_CONFIG.speechKey = window.__env__.SPEECH_KEY;
	APP_CONFIG.speechRegion = window.__env__.SPEECH_REGION;
	APP_CONFIG.epicAdventHSId = window.__env__.VITE_EPIC_ADVENT_HS_ID;
	APP_CONFIG.showReleaseVersion = window.__env__.VITE_SHOW_RELEASE_VERSION === 'true';
	APP_CONFIG.amwellTenantId = window.__env__.VITE_AMWELL_TENANT_ID;
	APP_CONFIG.banyanTenantId = window.__env__.VITE_BANYAN_TENANT_ID;
	APP_CONFIG.speechToTextKey = window.__env__.VITE_SPEECH_TO_TEXT_KEY;
	APP_CONFIG.speechToTextRegion = window.__env__.VITE_SPEECH_TO_TEXT_REGION;
	APP_CONFIG.lifeSignalsUrl = window.__env__.VITE_LIFE_SIGNALS_URL;
}

const opts = {
	baseURL: GatewayApiBasePath,
	timeout: 100000,
	headers: {
		'Content-Type': 'application/json',
	},
};

const gatewayOpts = {
	...opts,
	transformRequest: [data => JSON.stringify(data)],
};

const fitBitOpts = {
	...opts,
	baseURL: FitBitApiBasePath,
	transformRequest: [data => JSON.stringify(data)],
};

const clinicaltablesOpts = {
	...opts,
	baseURL: ClinicaltablesApiBasePath,
	transformRequest: [data => JSON.stringify(data)],
};

const mobileApiOpts = {
	...opts,
	baseURL: MobileApiBasePath,
	transformRequest: [data => JSON.stringify(data)],
};

const messengerOpts = {
	...opts,
	baseURL: APP_CONFIG.URL.messengerBasePath,
};

const legacyFileUploadOpts = {
	...opts,
	baseURL: `${APP_CONFIG.URL.legacyApiBasePath}/`,
	headers: {
		'Content-Type': 'multipart/form-data',
	},
};

const gatewayFileUploadOpts = {
	...legacyFileUploadOpts,
	baseURL: `${APP_CONFIG.URL.gatewayApiBasePath}/`,
};

const teamBotOpts = {
	...opts,
	baseURL: TeamBotApiBasePath,
};

const successHandler = response => {
	return new Promise(resolve => {
		resolve(response);
	});
};

const errorHandler = error => {
	if (error.response && error.response.status === 401) {
		signinSilent();
	}

	return new Promise((_, reject) => {
		reject(error);
	});
};

const legacyOpts = { ...opts, baseURL: LegacyApiBasePath };
const localOpts = { ...opts, baseURL: '/' };
const localOptsCookies = { ...opts, baseURL: '/', withCredentials: true };

export const solMessengerApi = axios.create(messengerOpts);
export const gatewayApi = axios.create(gatewayOpts);
export const whiteboardApi = axios.create(gatewayOpts);

export const fitBitApi = axios.create(fitBitOpts);
export const clinicalTablesApi = axios.create(clinicaltablesOpts);
export const legacyApi = axios.create(legacyOpts);
export const localApi = axios.create(localOpts);
export const localApiWithCookies = axios.create(localOptsCookies);
export const legacyFileUploadApi = axios.create(legacyFileUploadOpts);
export const gatewayFileUploadApi = axios.create(gatewayFileUploadOpts);
export const mobileApi = axios.create(mobileApiOpts);
export const healthCareCdnUrl = 'https://static.solaborate.com/healthcare-system/';
export const helloTwoImageUrl = 'https://static.solaborate.com/scoped/pages/hello-two';
export const helloTwoSpecsUrl = 'https://static.solaborate.com/scoped/pages/hello-two-specs';
export const primaryCareImageUrl = 'https://static.solaborate.com/healthcare-system//primary-care';
export const teamBotApi = axios.create(teamBotOpts);
export const globoTranslateServiceId = 19246;

const addAuth = async (request, retries = 0) => {
	const maxRetries = 5;

	let accessToken = window.Android?.getToken() ?? null;

	if (!accessToken && retries < maxRetries) {
		await new Promise(resolve => setTimeout(resolve, 2000));
		return addAuth(request, retries + 1);
	}

	if (!accessToken && retries >= maxRetries) {
		console.error('Max retries reached. Could not retrieve access token.');
		return request;
	}

	if (accessToken) {
		request.headers.Authorization = `Bearer ${accessToken}`;

		retries = 0;
	}

	return request;
};

const includeEncounterId = request => {
	const method = request.method.toLowerCase();
	const encounterIdKey = ['get', 'delete'].includes(method) ? 'encounter-id' : 'encounterId';
	const target = ['get', 'delete'].includes(method) ? 'params' : 'data';
	if (request?.useEncounterId !== false && encounterId) {
		request[target] = {
			...request[target],
			[encounterIdKey]: encounterId,
		};
	}
};

gatewayApi.interceptors.request.use(request => addAuth(request));
fitBitApi.interceptors.request.use(request => addAuth(request));
clinicalTablesApi.interceptors.request.use(request => addAuth(request));
mobileApi.interceptors.request.use(request => addAuth(request, getAccessToken()));
legacyFileUploadApi.interceptors.request.use(request => addAuth(request, getAccessToken()));
gatewayFileUploadApi.interceptors.request.use(request => addAuth(request, getAccessToken()));
legacyApi.interceptors.request.use(request => addAuth(request));
localApi.interceptors.request.use(request => addAuth(request));
teamBotApi.interceptors.request.use(request => addAuth(request));
whiteboardApi.interceptors.request.use(request => {
	includeEncounterId(request);
	return addAuth(request);
});

solMessengerApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
gatewayApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
legacyApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
localApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
legacyFileUploadApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
teamBotApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
whiteboardApi.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
);
