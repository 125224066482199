import { ConditionType } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';

export let encounterId = '';
export const setEncounterId = _encounterId => {
	encounterId = _encounterId;
};

export const getActiveEncounter = async (companyId, userId) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${companyId}/patients/${userId}/encounters/active`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPrecautions = async (ownerId, encounterId = '') => {
	try {
		const { data } = await gatewayApi.get(
			`v1.1/healthcare/patients/${ownerId}/conditions?conditionType=${ConditionType.PRECAUTION}&encounter-id=${encounterId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
